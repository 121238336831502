import React, { useEffect, useState } from "react";
import MultilingualForm from "@components/forms/multilingual";
import inputs from "./form/intputs";
import { Button, Drawer, Spin } from "antd";
import { SurveyQuestionOptionFormItems } from "./types";
import { useNavigate, useParams } from "react-router";
import {
    useGetQuestionOptionQuery,
    useUpdateSurveyQuestionOptionMutation
} from "@store/api/surveys";

const UpdateSurveyQuestionOption: React.FC = () => {
    const navigate = useNavigate();
    const [form] = MultilingualForm.useForm();
    const { id, questionId, optionId } = useParams();
    const [visible, setVisible] = useState<boolean>(false);
    const { data, isLoading: isLoadingOption, refetch } = useGetQuestionOptionQuery(optionId);
    const [updateOption, { isLoading }] = useUpdateSurveyQuestionOptionMutation();

    useEffect(() => {
        setVisible(true);
    }, []);

    useEffect(() => {
        if (data && form) {
            form.setFieldsValue(data);
        }
    }, [data, form]);

    const handleCloseDrawer = () => {
        setVisible(false);
        // waiting drawer closure
        setTimeout(() => {
            navigate(`/survey/${id}/questions/${questionId}/options`);
        }, 400);
    };

    const handleOnSubmit = async (values: SurveyQuestionOptionFormItems) => {
        await updateOption({
            optionId,
            ...values
        });
        await refetch();
        handleCloseDrawer();
    };

    return (
        <Drawer width={550} visible={visible} onClose={handleCloseDrawer} title="Update option">
            <Spin spinning={isLoadingOption}>
                <MultilingualForm
                    form={form}
                    inputs={inputs}
                    onSubmit={handleOnSubmit}
                    actions={[
                        <Button key="submit" type="primary" htmlType="submit" loading={isLoading}>
                            Update
                        </Button>
                    ]}
                />
            </Spin>
        </Drawer>
    );
};

export default UpdateSurveyQuestionOption;
