import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button, message, PageHeader, Spin } from "antd";
import styles from "./NewSurvey.module.scss";
import MultilingualForm from "@components/forms/multilingual";
import ErrorMessage from "@components/ErrorMessage";
import inputs from "./form/new-survey-inputs";
import { SurveyFormItems } from "./types";
import {
    useGetSurveyQuery,
    useUpdateSurveyMutation,
    useGetTimezonesQuery
} from "@store/api/surveys";
import { utcToTimeZone, timeZoneToUtc } from "@utils/timezones";
import { processFormValue } from "@utils/general";

const Details = () => {
    const dateFormat = "YYYY-MM-DD HH:mm";
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = MultilingualForm.useForm();

    const { data, error: errorSurvey, isLoading: loading, refetch } = useGetSurveyQuery(id);
    const {
        data: timezones,
        isFetching: isFetchingTimezones,
        error: errorTimezones
    } = useGetTimezonesQuery("");
    const [updateSurvey, { isLoading }] = useUpdateSurveyMutation();

    useEffect(() => {
        if (errorSurvey) {
            message.error("Error on try to get survey details");
        }
        if (data) {
            const openConverted = data.open_at
                ? utcToTimeZone({ time: data.open_at, zone: data.timezone, format: dateFormat })
                : undefined;
            const closeConverted = data.close_at
                ? utcToTimeZone({ time: data.close_at, zone: data.timezone, format: dateFormat })
                : undefined;

            form.setFieldsValue({
                ...data,
                description: processFormValue(data.description),
                closed_message: processFormValue(data.closed_message),
                pre_message: processFormValue(data.pre_message),
                post_message: processFormValue(data.post_message),
                close_at: closeConverted ? moment(closeConverted) : undefined,
                open_at: openConverted ? moment(openConverted) : undefined,
                is_active: !!data.is_active
            });
        }
    }, [form, data, errorSurvey]);

    const handleBack = () => navigate(`/`);
    const handleOnSubmit = async (values: SurveyFormItems) => {
        const openAtDate = values.open_at
            ? timeZoneToUtc({ time: values.open_at, zone: values.timezone, format: dateFormat })
            : null;
        const closeAtDate = values.close_at
            ? timeZoneToUtc({ time: values.close_at, zone: values.timezone, format: dateFormat })
            : null;

        await updateSurvey({
            id,
            ...values,
            timezone: values.timezone,
            open_at: openAtDate,
            close_at: closeAtDate
        });
        await refetch();
    };

    const error = errorSurvey || errorTimezones;

    return (
        <div>
            <PageHeader
                onBack={handleBack}
                title="Update survey"
                extra={[
                    <Link key="questions" to={`/survey/${id}/questions`}>
                        <Button>Questions</Button>
                    </Link>,
                    <Link key="answers" to={`/survey/${id}/answers`}>
                        <Button>Answers</Button>
                    </Link>,
                    <Link key="styles" to={`/survey/${id}/styles`}>
                        <Button>Styles</Button>
                    </Link>,
                    <Link key="files-and images" to={`/survey/${id}/files-and-images`}>
                        <Button>Files and Images</Button>
                    </Link>
                ]}
            />
            <Spin spinning={loading}>
                <div className={styles.page}>
                    {!isFetchingTimezones && !error && (
                        <MultilingualForm
                            form={form}
                            inputs={inputs({ timezones })}
                            onSubmit={handleOnSubmit}
                            actions={[
                                <Button
                                    key="submit"
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                >
                                    Update
                                </Button>
                            ]}
                        />
                    )}
                    {error && <ErrorMessage message="There was an error, please try again later" />}
                </div>
            </Spin>
        </div>
    );
};

export default Details;
