import React from "react";
import NoSelected from "@components/NoSelected";

const Home = () => {
    return (
        <NoSelected title="Please select survey or create " buttonText="New" link="/survey/new" />
    );
};

export default Home;
