import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { Link, Outlet } from "react-router-dom";
import { Button, Popconfirm, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Layout, Left, Right } from "@components/layout";
import Card from "@components/Card";
import { useDeleteSurveyMutation, useGetSurveysQuery } from "@store/api/surveys";

import styles from "./Surveys.module.scss";

const Surveys = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const { data, isFetching } = useGetSurveysQuery(currentPage);
    const [deleteSurvey, { originalArgs: deletedSurveyId, isLoading: isDeleting }] =
        useDeleteSurveyMutation();
    const handleDeleteSurvey = async (pid: string) => {
        await deleteSurvey(pid);
        navigate("/");
    };

    return (
        <Layout>
            <Left
                action={
                    <Link to="/survey/new">
                        <Button className={styles.btn} type="primary" size="small">
                            + New
                        </Button>
                    </Link>
                }
                title="Surveys"
                loadingItems={isFetching}
                items={data?.surveys || []}
                pagination={data?.pagination}
                onPageChange={(page) => setCurrentPage(page)}
                renderItem={(survey) => (
                    <Spin spinning={isDeleting && deletedSurveyId === survey.id} key={survey.id}>
                        <div className={styles.card}>
                            <Link to={`/survey/${survey.id}`} data-testid="survey-card">
                                <Card
                                    published={
                                        survey.created_at
                                            ? moment(survey.created_at).fromNow()
                                            : undefined
                                    }
                                    updated={
                                        survey.updated_at
                                            ? moment(survey.updated_at).fromNow()
                                            : undefined
                                    }
                                    title={survey.title?.it || survey.title?.en}
                                    selected={id !== undefined && survey.id === id}
                                />
                            </Link>
                            <Popconfirm
                                title="Are you sure to delete this survey?"
                                okText="Delete"
                                onConfirm={() => handleDeleteSurvey(survey.id)}
                            >
                                <DeleteOutlined className={styles.delete} />
                            </Popconfirm>
                        </div>
                    </Spin>
                )}
            />
            <Right>
                <Outlet />
            </Right>
        </Layout>
    );
};

export default Surveys;
