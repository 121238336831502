import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import moment from "moment";
import { saveAs } from "file-saver";
import { Button, message, PageHeader, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table/interface";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { useGetSurveyAnswersQuery } from "@store/api/surveys";
import { Page } from "@components/layout";
import { useSelector } from "react-redux";
import { selectUser } from "@store/slices/user";

interface DataType {
    id: string;
    signature: number;
    question: any;
    option: any;
    updated_at: string;
}

const columns: ColumnsType<DataType> = [
    {
        key: "question",
        title: "Question",
        dataIndex: "question",
        render: ({ title }) => <div data-testid="answer-question-title">{title}</div>
    },
    {
        key: "options",
        title: "Options",
        dataIndex: "options",
        render: (options: any) => options?.map((option: any) => <div>{option.title}</div>)
    },
    {
        key: "signature",
        title: "Signature",
        dataIndex: "signature"
    },
    {
        key: "created_at",
        title: "Date",
        dataIndex: "created_at",
        render: (data) => moment(data).format("DD/MM/YYYY HH:mm:ss")
    }
];

const DEFAULT_PAGE = 1;

const Answers: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { token } = useSelector(selectUser);
    const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
    const [downloadingExport, toggleDownloadingExport] = useState<boolean>(false);
    const { data, isFetching } = useGetSurveyAnswersQuery({
        page: currentPage,
        surveyId: id
    });

    const handleBack = () => navigate(`/survey/${id}`);
    const handleDownloadFile = async () => {
        if (!downloadingExport) {
            toggleDownloadingExport(true);

            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/admin/surveys/${id}/export`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        responseType: "blob"
                    }
                );
                const blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, id);
            } catch (error: any) {
                message.error(error.message);
            }

            toggleDownloadingExport(false);
        }
    };
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || DEFAULT_PAGE);
    };

    return (
        <div>
            <PageHeader
                onBack={handleBack}
                title="Survey answers"
                extra={[
                    <Button
                        loading={downloadingExport}
                        onClick={handleDownloadFile}
                        icon={<CloudDownloadOutlined />}
                        disabled={data === undefined || data?.answers.length === 0}
                        key="export"
                        data-testid="export-answers-button"
                    >
                        Export answers
                    </Button>
                ]}
            />
            <Page>
                <Table
                    loading={isFetching}
                    bordered
                    rowKey="id"
                    dataSource={data?.answers || []}
                    columns={columns}
                    onChange={handleTableChange}
                    pagination={{
                        total: data?.pagination.total || 0,
                        defaultCurrent: currentPage,
                        pageSize: data?.pagination.perPage || 0
                    }}
                />
            </Page>
        </div>
    );
};

export default Answers;
