import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { RootState } from "@store/index";
import type { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

import { KeyName, MediaItem, GalleryItem, CSSStyle, CSSStyleKeyName } from "./types";

const SURVEYS = "Surveys";
const QUESTIONS = "Questions";
const OPTIONS = "Options";
const ANSWERS = "Answers";
const MEDIAS = "Medias";
const GALLERY = "Gallery";
const CSS_STYLES = "CSS_Styles";
const surveysApi = createApi({
    reducerPath: "surveys",
    tagTypes: [SURVEYS, QUESTIONS, OPTIONS, ANSWERS, MEDIAS, GALLERY, CSS_STYLES],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/admin`,
        prepareHeaders: (headers, { getState }) => {
            const {
                user: { token }
            } = getState() as RootState;
            headers.set(`Authorization`, `Bearer ${token}`);

            return headers;
        }
    }),
    endpoints: (build) => ({
        // SURVEYS
        getSurveys: build.query({
            query: (page = 1) => `/surveys?page=${page}`,
            transformResponse: (res: BaseQueryResult<any>): any => ({
                surveys: res.data,
                pagination: {
                    total: res.meta.total,
                    perPage: res.meta.per_page,
                    current: res.meta.current_page
                }
            }),
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.surveys.map(({ id }: any) => ({ type: SURVEYS, id })),
                          { type: SURVEYS, id: "LIST" }
                      ]
                    : [{ type: SURVEYS, id: "LIST" }]
        }),
        getSurvey: build.query({
            query: (id) => `/surveys/${id}`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        addSurvey: build.mutation({
            query: (body) => ({
                url: `/surveys`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: SURVEYS, id: "LIST" }]
        }),
        updateSurvey: build.mutation({
            query: ({ id, ...body }) => {
                return {
                    url: `/surveys/${id}`,
                    method: "PUT",
                    body
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: SURVEYS, id: "LIST" }]
        }),
        deleteSurvey: build.mutation({
            query: (id) => ({
                url: `/surveys/${id}`,
                method: "DELETE"
            }),
            transformResponse: (res: BaseQueryResult<any>) => res,
            invalidatesTags: [{ type: SURVEYS, id: "LIST" }]
        }),
        getSurveyAnswers: build.query({
            query: ({ page = 1, surveyId }) => `/surveys/${surveyId}/answers?page=${page}`,
            transformResponse: (res: BaseQueryResult<any>): any => ({
                answers: res.data,
                pagination: {
                    total: res.meta.total,
                    perPage: res.meta.per_page,
                    current: res.meta.current_page
                }
            }),
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.answers.map(({ id }: any) => ({ type: ANSWERS, id })),
                          { type: ANSWERS, id: "LIST" }
                      ]
                    : [{ type: ANSWERS, id: "LIST" }]
        }),
        // SURVEY QUESTIONS
        getSurveyQuestions: build.query({
            query: (id) => `/surveys/${id}/questions`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: QUESTIONS, id })),
                          { type: QUESTIONS, id: "LIST" }
                      ]
                    : [{ type: QUESTIONS, id: "LIST" }]
        }),
        addSurveyQuestion: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/questions`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: QUESTIONS, id: "LIST" }]
        }),
        getSurveyQuestion: build.query({
            query: (questionId) => `/questions/${questionId}`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        updateSurveyQuestion: build.mutation({
            query: ({ questionId, ...body }) => ({
                url: `/questions/${questionId}`,
                method: "PUT",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: QUESTIONS, id: "LIST" }]
        }),
        updateQuestionsOrder: build.mutation({
            query: ({ surveyId, order }) => ({
                url: `/surveys/${surveyId}/questions/sort`,
                method: "POST",
                body: {
                    positions: order
                }
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: QUESTIONS, id: "LIST" }]
        }),
        deleteSurveyQuestion: build.mutation({
            query: (questionId) => ({
                url: `/questions/${questionId}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: QUESTIONS, id: "LIST" }]
        }),
        // QUESTION OPTIONS
        getQuestionOptions: build.query({
            query: (id) => `/questions/${id}/options`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: OPTIONS, id })),
                          { type: OPTIONS, id: "LIST" }
                      ]
                    : [{ type: OPTIONS, id: "LIST" }]
        }),
        addSurveyQuestionOption: build.mutation({
            query: ({ questionId, ...body }) => ({
                url: `/questions/${questionId}/options`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: OPTIONS, id: "LIST" }]
        }),
        getQuestionOption: build.query({
            query: (id) => `/options/${id}`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        updateSurveyQuestionOption: build.mutation({
            query: ({ optionId, ...body }) => ({
                url: `/options/${optionId}`,
                method: "PUT",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: OPTIONS, id: "LIST" }]
        }),
        deleteSurveyQuestionOption: build.mutation({
            query: (optionId) => ({
                url: `/options/${optionId}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: OPTIONS, id: "LIST" }]
        }),
        updateOptionsOrder: build.mutation({
            query: ({ questionId, order }) => ({
                url: `/questions/${questionId}/options/sort`,
                method: "POST",
                body: {
                    positions: order
                }
            }),
            invalidatesTags: [{ type: OPTIONS, id: "LIST" }]
        }),
        // MEDIA KEY NAMES
        getSurveyMediaKeyNames: build.query<KeyName[], string>({
            query: () => `/media-keynames`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        // MEDIAS
        getSurveyMedias: build.query<MediaItem[], string>({
            query: (id) => `/surveys/${id}/media`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: MEDIAS, id })),
                          { type: MEDIAS, id: "LIST" }
                      ]
                    : [{ type: MEDIAS, id: "LIST" }]
        }),
        attachSurveyMedias: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/attachMedia`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: MEDIAS, id: "LIST" }]
        }),
        detachSurveyMedias: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/detachMedia`,
                method: "DELETE",
                body
            }),
            invalidatesTags: [{ type: MEDIAS, id: "LIST" }]
        }),
        // GALLERY
        getSurveyGallery: build.query<GalleryItem[], string>({
            query: (id) => `/surveys/${id}/gallery`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: GALLERY, id })),
                          { type: GALLERY, id: "LIST" }
                      ]
                    : [{ type: GALLERY, id: "LIST" }]
        }),
        saveUploadedGalleryMedia: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `surveys/${surveyId}/media/save-uploaded`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: GALLERY, id: "LIST" }]
        }),
        // STYLES
        getCssStylesKeyNames: build.query<CSSStyleKeyName[], string>({
            query: () => `/style-keynames`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        getCssStyles: build.query<CSSStyle[], string>({
            query: (id) => `/surveys/${id}/styles`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: GALLERY, id })),
                          { type: CSS_STYLES, id: "LIST" }
                      ]
                    : [{ type: CSS_STYLES, id: "LIST" }]
        }),
        changeCssStyles: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/styles`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: CSS_STYLES, id: "LIST" }]
        }),
        // TIMEZONES
        getTimezones: build.query({
            query: () => `/timezones`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        })
    })
});

export default surveysApi;

export const {
    useGetSurveysQuery,
    useGetSurveyQuery,
    useDeleteSurveyMutation,
    useAddSurveyMutation,
    useUpdateSurveyMutation,
    useGetSurveyAnswersQuery,
    useGetSurveyQuestionsQuery,
    useAddSurveyQuestionMutation,
    useGetSurveyQuestionQuery,
    useUpdateSurveyQuestionMutation,
    useUpdateQuestionsOrderMutation,
    useDeleteSurveyQuestionMutation,
    useGetQuestionOptionsQuery,
    useAddSurveyQuestionOptionMutation,
    useGetQuestionOptionQuery,
    useUpdateSurveyQuestionOptionMutation,
    useDeleteSurveyQuestionOptionMutation,
    useUpdateOptionsOrderMutation,
    useGetSurveyMediaKeyNamesQuery,
    useGetSurveyMediasQuery,
    useGetSurveyGalleryQuery,
    useDetachSurveyMediasMutation,
    useAttachSurveyMediasMutation,
    useSaveUploadedGalleryMediaMutation,
    useGetCssStylesKeyNamesQuery,
    useGetCssStylesQuery,
    useChangeCssStylesMutation,
    useGetTimezonesQuery
} = surveysApi;
