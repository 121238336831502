import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Button, Drawer } from "antd";
import { useAddSurveyQuestionOptionMutation } from "@store/api/surveys";
import MultilingualForm from "@components/forms/multilingual";
import inputs from "./form/intputs";
import { SurveyQuestionOptionFormItems } from "./types";

const NewSurveyQuestionOption: React.FC = () => {
    const navigate = useNavigate();
    const { id, questionId } = useParams();
    const [visible, setVisible] = useState<boolean>(false);
    const [addOption, { isLoading }] = useAddSurveyQuestionOptionMutation();

    useEffect(() => {
        setVisible(true);
    }, []);

    const handleCloseDrawer = () => {
        setVisible(false);
        setTimeout(() => {
            navigate(`/survey/${id}/questions/${questionId}/options`);
        }, 400);
    };

    const handleOnSubmit = async (values: SurveyQuestionOptionFormItems) => {
        await addOption({
            questionId,
            ...values
        });
        handleCloseDrawer();
    };

    return (
        <Drawer width={550} visible={visible} onClose={handleCloseDrawer} title="Create new option">
            <MultilingualForm
                inputs={inputs}
                onSubmit={handleOnSubmit}
                actions={[
                    <Button key="submit" type="primary" htmlType="submit" loading={isLoading}>
                        Create new
                    </Button>
                ]}
            />
        </Drawer>
    );
};

export default NewSurveyQuestionOption;
