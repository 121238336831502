import { MultilingualFormInput } from "@components/forms/multilingual";
import inputs from "./intputs";

const formInputs: MultilingualFormInput[] = [
    ...inputs,
    {
        name: "is_radio",
        label: "Is Radio button?",
        type: "switch",
        multilingual: false
    },
    {
        name: "is_icon",
        label: "Is Icon?",
        type: "switch",
        multilingual: false
    },
    {
        name: "min_number_of_answers",
        label: "Min number of answers",
        multilingual: false
    },
    {
        name: "max_number_of_answers",
        label: "Max number of answers",
        multilingual: false
    }
];

export default formInputs;
