import React from "react";
import { useNavigate, useParams } from "react-router";
import { PageHeader, List, Spin } from "antd";
import { Page } from "@components/layout";
import {
    useGetCssStylesKeyNamesQuery,
    useGetCssStylesQuery,
    useChangeCssStylesMutation
} from "@store/api/surveys";
import CSSStyleListItem from "@components/CSSStyleListItem";
import ErrorMessage from "@components/ErrorMessage";

const FilesAndImages: React.FC = () => {
    const navigate = useNavigate();
    const { id: surveyId } = useParams();
    const handleBack = () => navigate(`/survey/${surveyId}`);

    const {
        data: cssStyleKeyNames,
        isFetching: isFetchingCssStylesKeyNames,
        error: errorGetCssKeyNames
    } = useGetCssStylesKeyNamesQuery(surveyId!);
    const {
        data: cssStyles,
        isFetching: isFetchingCssStyles,
        error: errorGetCssStyles
    } = useGetCssStylesQuery(surveyId!);
    const [changeStyles, { error: errorChangeStyles }] = useChangeCssStylesMutation();

    const isLoading = isFetchingCssStylesKeyNames || isFetchingCssStyles;
    const error = errorChangeStyles || errorGetCssKeyNames || errorGetCssStyles;

    return (
        <div>
            <PageHeader onBack={handleBack} title={"Styles"} />
            <Page>
                {!error ? (
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        loading={isLoading}
                        dataSource={cssStyleKeyNames}
                        renderItem={(item) => {
                            const match = cssStyles?.find(
                                (cssStyle) => cssStyle.fieldname === item.keyname
                            );
                            return (
                                <CSSStyleListItem
                                    item={item}
                                    match={match || null}
                                    isLoadingStyles={isFetchingCssStyles}
                                    changeStyles={changeStyles}
                                />
                            );
                        }}
                    />
                ) : (
                    <ErrorMessage message="There was an error, please try again later" />
                )}
            </Page>
        </div>
    );
};

export default FilesAndImages;
