import React, { useEffect, useState } from "react";
import { List, Button, Input } from "antd";
import { useParams } from "react-router-dom";
import { CSSStyleKeyName, CSSStyle } from "@store/api/types";
import ColorPicker from "@components/ColorPicker";

interface CSSStylesListItemProps {
    item: CSSStyleKeyName;
    match: CSSStyle | null;
    isLoadingStyles: boolean;
    changeStyles: (stylesInfo: {
        surveyId: string;
        fieldname: string;
        fieldvalue: string | null;
    }) => Promise<any>;
}

const CSSStylesListItem: React.FC<CSSStylesListItemProps> = ({
    item,
    match,
    isLoadingStyles,
    changeStyles
}) => {
    const { id: surveyId } = useParams();
    const initialValue = match === null ? "" : match.fieldvalue;
    const [value, setValue] = useState<string | null>(initialValue);
    const [updateEnabled, setUpdateEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (value !== match?.fieldvalue) setUpdateEnabled(true);
        else setUpdateEnabled(false);
    }, [value, match]);

    useEffect(() => {
        if (match === null) return;
        setValue(match.fieldvalue || "");
    }, [match]);

    return (
        <List.Item
            actions={[
                <Button
                    disabled={!updateEnabled || isLoadingStyles}
                    type={updateEnabled && !isLoadingStyles ? "primary" : "default"}
                    onClick={async () =>
                        await changeStyles({
                            surveyId: surveyId!,
                            fieldname: item.keyname,
                            fieldvalue: value?.trim() || null
                        })
                    }
                    data-testid="update-style-button"
                >
                    Save
                </Button>
            ]}
            data-testid="css-style-listitem"
        >
            <List.Item.Meta
                title={item.description}
                // description={
                //     <a
                //         href="https://developer.mozilla.org/en-US/docs/Web/CSS/background-position"
                //         target="_blank"
                //     >
                //         Read MDN Docs
                //     </a>
                // }
            />
            <div style={{ width: "40%" }}>
                {item.type === "color" ? (
                    <ColorPicker color={value || "#fff"} onChangeComplete={setValue} />
                ) : (
                    <Input
                        type="text"
                        value={value || ""}
                        onChange={(e) => setValue(e.target.value)}
                        data-testid="css-style-input"
                    />
                )}
            </div>
        </List.Item>
    );
};

export default CSSStylesListItem;
