import React from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
import { Link, Outlet } from "react-router-dom";
import { Button, PageHeader, Popconfirm, Spin } from "antd";
import { Layout, Left, Right } from "@components/layout";
import {
    useDeleteSurveyQuestionMutation,
    useGetSurveyQuestionsQuery,
    useUpdateQuestionsOrderMutation
} from "@store/api/surveys";
import Card from "@components/Card";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "./Surveys.module.scss";

export interface QuestionsProps {}

const Questions: React.FC<QuestionsProps> = () => {
    const navigate = useNavigate();
    const { id: surveyId, questionId } = useParams();
    const { data, isFetching } = useGetSurveyQuestionsQuery(surveyId);
    const [deleteQuestion] = useDeleteSurveyQuestionMutation();
    const [updateOrder] = useUpdateQuestionsOrderMutation();

    const handleBack = () => navigate(`/survey/${surveyId}`);
    const handleDeleteQuestion = async (id: string) => {
        await deleteQuestion(id);
        if (questionId) {
            navigate(`/survey/${surveyId}/questions`);
        }
    };
    const handleSaveOrder = async (items: any) => {
        const order = items.map(({ id }: any) => id);
        await updateOrder({ surveyId, order });
    };

    return (
        <div>
            <PageHeader onBack={handleBack} title="Survey questions" />
            <Layout height="calc(100vh - 200px)">
                <Left
                    height="calc(100vh - 200px)"
                    canOrder
                    onSaveOrder={handleSaveOrder}
                    action={
                        <Link
                            to={`/survey/${surveyId}/questions/new`}
                            data-testid="new-question-button"
                        >
                            <Button type="primary" size="small">
                                + New
                            </Button>
                        </Link>
                    }
                    withSearch={false}
                    title="Questions"
                    loadingItems={isFetching}
                    items={data}
                    renderItem={(question) => (
                        <Spin key={question.id} spinning={false}>
                            <div className={styles.card} data-testid="question-card">
                                <Link to={`/survey/${surveyId}/questions/${question.id}`}>
                                    <Card
                                        published={
                                            question.created_at
                                                ? moment(question.created_at).fromNow()
                                                : undefined
                                        }
                                        updated={
                                            question.updated_at
                                                ? moment(question.updated_at).fromNow()
                                                : undefined
                                        }
                                        title={question.title?.it || question.title?.en}
                                        selected={
                                            surveyId !== undefined && question.id === questionId
                                        }
                                    />
                                </Link>
                                <Popconfirm
                                    title="Are you sure to delete this question?"
                                    okText="Delete"
                                    onConfirm={() => handleDeleteQuestion(question.id)}
                                >
                                    <DeleteOutlined className={styles.delete} />
                                </Popconfirm>
                            </div>
                        </Spin>
                    )}
                />
                <Right height="calc(100vh - 200px)">
                    <Outlet />
                </Right>
            </Layout>
        </div>
    );
};

export default Questions;
