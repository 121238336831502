import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Input, Pagination, Spin, Tooltip } from "antd";
import { DownCircleOutlined, SortAscendingOutlined, UpCircleOutlined } from "@ant-design/icons";

import styles from "@components/layout/Left.module.scss";

export interface LeftProps {
    title?: string;
    canOrder?: boolean;
    action?: React.ReactNode;
    loadingItems?: boolean;
    items?: any[];
    pagination?: any;
    renderItem?: (item: any) => React.ReactNode;
    height?: string | number;
    onPageChange?: (page: any) => void;
    withSearch?: boolean;
    onSearch?: (
        value: string,
        event?:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLElement>
            | React.KeyboardEvent<HTMLInputElement>
    ) => void;
    onSaveOrder?: (items: any) => Promise<void>;
}

const Left: React.FC<LeftProps> = ({
    action,
    canOrder = false,
    loadingItems = false,
    renderItem,
    title,
    height,
    withSearch = true,
    onSearch,
    items,
    pagination,
    onPageChange,
    onSaveOrder
}) => {
    const [localItems, setItems] = useState<any>([]);
    const [needToSaveOrder, toggleNeedToSaveOrder] = useState<boolean>(false);
    const [order, toggleOrdering] = useState<boolean>(false);
    const [savingOrder, toggleSavingOrder] = useState<boolean>(false);

    const style: CSSProperties = useMemo(() => {
        const _styles: CSSProperties = {};

        if (height !== undefined) {
            _styles.height = height;
        }

        return _styles;
    }, [height]);

    const getDefaultItemsState = useCallback(() => {
        setItems(items);
    }, [items]);

    const handleToggleCanOrder = () => {
        if (order && needToSaveOrder) {
            toggleNeedToSaveOrder(false);
            getDefaultItemsState();
        }

        toggleOrdering(!order);
    };

    const handlePutUp = (i: number) => {
        const _items = [...localItems];
        const temp = _items[i];
        _items[i] = _items[i - 1];
        _items[i - 1] = temp;
        setItems(_items);
        toggleNeedToSaveOrder(true);
    };

    const handlePutDown = (i: number) => {
        const _items = [...localItems];
        const temp = _items[i];
        _items[i] = _items[i + 1];
        _items[i + 1] = temp;
        setItems(_items);
        toggleNeedToSaveOrder(true);
    };

    const handleSaveOrder = async () => {
        if (onSaveOrder) {
            toggleSavingOrder(true);
            await onSaveOrder(localItems);
            toggleSavingOrder(false);
            toggleOrdering(false);
        } else {
            throw Error("Missing async method onSaveOrder");
        }
    };

    useEffect(() => {
        if (items) {
            getDefaultItemsState();
        }
    }, [items, getDefaultItemsState]);

    return (
        <div className={styles.left} style={style}>
            <div className={styles.header}>
                <div className={styles.top}>
                    {title && <h2>{title}</h2>}
                    {action && (
                        <div className={styles.actions}>
                            <div>
                                {order ? (
                                    <div>
                                        {needToSaveOrder && (
                                            <Button
                                                loading={savingOrder}
                                                size="small"
                                                onClick={handleSaveOrder}
                                            >
                                                Save order
                                            </Button>
                                        )}
                                    </div>
                                ) : (
                                    action
                                )}
                            </div>
                            {canOrder && (
                                <Tooltip
                                    placement="top"
                                    title={`${order ? "Disable" : "Enable"} order`}
                                >
                                    <Button
                                        size="small"
                                        type={order ? "primary" : "default"}
                                        onClick={handleToggleCanOrder}
                                        icon={<SortAscendingOutlined />}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>
                <div>
                    {withSearch && (
                        <Input.Search
                            onSearch={onSearch}
                            size="small"
                            allowClear
                            className={styles.search}
                        />
                    )}
                </div>
            </div>
            <Spin spinning={loadingItems}>
                <div className={styles.items}>
                    {localItems?.map(
                        (item: any, i: number) =>
                            renderItem && (
                                <div className={styles.listItem} key={i}>
                                    <div className={styles.item}>{renderItem(item)}</div>
                                    {order && (
                                        <div className={styles.orderIcons}>
                                            <div className={styles.orderIconWrapper}>
                                                {i !== 0 && (
                                                    <UpCircleOutlined
                                                        className={styles.orderIcon}
                                                        onClick={() => handlePutUp(i)}
                                                    />
                                                )}
                                            </div>
                                            <div className={styles.orderIconWrapper}>
                                                {i !== localItems.length - 1 && (
                                                    <DownCircleOutlined
                                                        className={styles.orderIcon}
                                                        onClick={() => handlePutDown(i)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                    )}
                </div>
            </Spin>
            {pagination && (
                <div className={styles.pagination}>
                    <Pagination
                        simple
                        defaultCurrent={pagination.current}
                        total={pagination.total}
                        pageSize={pagination.perPage}
                        onChange={onPageChange}
                    />
                </div>
            )}
        </div>
    );
};

export default Left;
