import { MultilingualFormInput } from "@components/forms/multilingual";

const inputs: MultilingualFormInput[] = [
    {
        name: "title",
        label: "Title",
        rules: [{ required: true, message: "Please insert title" }]
    },
    {
        name: "subtitle",
        label: "Subtitle"
    },
    {
        name: "description",
        label: "Description",
        type: "rich-text-editor"
    }
];

export default inputs;
