import React from "react";
import { useParams } from "react-router";
import { Button, PageHeader } from "antd";
import { Link } from "react-router-dom";
import { useAddSurveyQuestionMutation } from "@store/api/surveys";
import MultilingualForm from "@components/forms/multilingual";
import inputs from "./form/intputs";
import { SurveyQuestionFormItems } from "./types";

import styles from "./NewSurvey.module.scss";

const NewQuestion: React.FC = () => {
    const { id: surveyId } = useParams();

    const [addSurvey, { data, isLoading, isSuccess, reset }] = useAddSurveyQuestionMutation();

    const handleOnSubmit = async (values: SurveyQuestionFormItems) =>
        await addSurvey({ ...values, surveyId });
    const handleResetSuccess = () => reset();

    return (
        <div>
            <PageHeader title="New survey question" />
            <div className={styles.page}>
                {!isSuccess && (
                    <MultilingualForm
                        inputs={inputs}
                        onSubmit={handleOnSubmit}
                        actions={[
                            <Button
                                key="submit"
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                data-testid="new-question-submit"
                            >
                                Create new
                            </Button>
                        ]}
                    />
                )}
                {isSuccess && (
                    <div className={styles.success}>
                        <div className={styles.title}>Successfully created the new question</div>
                        <div className={styles.actions} data-testid="new-question-actions">
                            <Button type="primary" onClick={handleResetSuccess}>
                                Create new
                            </Button>
                            <Link to={`/survey/${surveyId}/questions/${data.id}`}>
                                <Button>Go to created question</Button>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewQuestion;
