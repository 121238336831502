import React from "react";
import { useParams } from "react-router";
import NoSelected from "@components/NoSelected";

const HomeSurveyQuestions: React.FC = () => {
    const { id } = useParams();
    return (
        <NoSelected
            title="Please select question or create "
            buttonText="New"
            link={`/survey/${id}/questions/new`}
        />
    );
};

export default HomeSurveyQuestions;
