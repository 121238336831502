import moment from "moment";
import momentTimezone from "moment-timezone";

export const utcToTimeZone = ({
    time,
    zone,
    format
}: {
    time: string;
    zone: string;
    format: string;
}): string => {
    const unformatted = momentTimezone.utc(time).tz(zone || "utc");
    return unformatted.format(format);
};

export const timeZoneToUtc = ({
    time,
    zone,
    format
}: {
    time: string;
    zone: string | undefined;
    format: string;
}): string => {
    return momentTimezone
        .tz(moment(time).format(format), format, zone || "utc")
        .utc()
        .format(format);
};
