import React from "react";
import { List, Button } from "antd";
import { useParams } from "react-router-dom";
import { sizeFormatter } from "human-readable";
import { GalleryItem } from "@store/api/types";

interface GalleryListItemProps {
    item: GalleryItem;
    keyNameToUpload: string | null;
    setIsDrawerOpen: (val: boolean) => void;
    attachMedia: (mediaInfo: {
        surveyId: string;
        media_id: string;
        fieldname: string | null;
    }) => Promise<any>;
}

const GalleryListItem: React.FC<GalleryListItemProps> = ({
    item,
    keyNameToUpload,
    setIsDrawerOpen,
    attachMedia
}) => {
    const { id: surveyId } = useParams();
    const defaults = sizeFormatter();

    return (
        <List.Item
            key={item.filename}
            extra={<img width={272} src={item.url} alt="gallery item" />}
        >
            <List.Item.Meta
                title={item.filename}
                description={"Size: " + defaults(parseInt(item.size))}
            />
            <Button
                onClick={async () => {
                    await attachMedia({
                        surveyId: surveyId!,
                        media_id: item.id,
                        fieldname: keyNameToUpload
                    });
                    setIsDrawerOpen(false);
                }}
                type="primary"
            >
                Attach
            </Button>
        </List.Item>
    );
};

export default GalleryListItem;
