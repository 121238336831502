import { Button, PageHeader, Spin } from "antd";
import React, { useEffect } from "react";
import { useGetSurveyQuestionQuery, useUpdateSurveyQuestionMutation } from "@store/api/surveys";

import { Link } from "react-router-dom";
import MultilingualForm from "@components/forms/multilingual";
import { SurveyQuestionFormItems } from "./types";
import inputs from "./form/new-question-inputs";
import { useParams } from "react-router";

const UpdateQuestion: React.FC = () => {
    const { id: surveyId, questionId } = useParams();
    const [form] = MultilingualForm.useForm();
    const {
        data,
        isFetching: isLoadingQuestion,
        refetch: refetchQuestion
    } = useGetSurveyQuestionQuery(questionId);
    const [updateSurvey, { isLoading: isUpdating }] = useUpdateSurveyQuestionMutation();

    useEffect(() => {
        if (data && form) {
            form.resetFields();
            form.setFieldsValue(data);
        }
    }, [data, form]);

    const handleOnSubmit = async (values: SurveyQuestionFormItems) => {
        await updateSurvey({ ...values, questionId });
        refetchQuestion();
    };

    return (
        <div>
            <Spin spinning={isLoadingQuestion}>
                <PageHeader
                    title="Survey question"
                    extra={[
                        <Link key="link" to={`/survey/${surveyId}/questions/${questionId}/options`}>
                            <Button>Options</Button>
                        </Link>
                    ]}
                />
                <MultilingualForm
                    form={form}
                    inputs={inputs}
                    onSubmit={handleOnSubmit}
                    actions={[
                        <Button key="submit" type="primary" htmlType="submit" loading={isUpdating}>
                            Update
                        </Button>
                    ]}
                />
            </Spin>
        </div>
    );
};

export default UpdateQuestion;
