import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import store from "./store";
import Hocs from "./hocs";
import { getToken } from "@utils/auth";
import { stickToken } from "@store/slices/user";
import App from "./App";
import {
    HomeSurvey,
    HomeSurveyQuestions,
    Login,
    NewSurvey,
    NewSurveyQuestion,
    NewSurveyQuestionOption,
    SurveyAnswers,
    SurveyDetails,
    SurveyQuestionOptions,
    SurveyQuestions,
    Surveys,
    UpdateSurveyQuestion,
    UpdateSurveyQuestionOption,
    SurveyStyles,
    SurveyFilesAndImages
} from "./pages";

import "./styles/global.css";
import "./styles/overwrite-antd.less";

const token = getToken();
if (token) {
    store.dispatch(stickToken(token));
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Hocs>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route path="/" element={<Surveys />}>
                            <Route index element={<HomeSurvey />} />
                            <Route path="/survey/:id" element={<SurveyDetails />} />
                            <Route path="/survey/:id/answers" element={<SurveyAnswers />} />
                            <Route path="/survey/new" element={<NewSurvey />} />
                            <Route path="/survey/:id/questions" element={<SurveyQuestions />}>
                                <Route index element={<HomeSurveyQuestions />} />
                                <Route path="new" element={<NewSurveyQuestion />} />
                                <Route path=":questionId" element={<UpdateSurveyQuestion />} />
                                <Route
                                    path=":questionId/options"
                                    element={<SurveyQuestionOptions />}
                                >
                                    <Route path="new" element={<NewSurveyQuestionOption />} />
                                    <Route
                                        path=":optionId"
                                        element={<UpdateSurveyQuestionOption />}
                                    />
                                </Route>
                            </Route>
                            <Route path="/survey/:id/styles" element={<SurveyStyles />} />
                            <Route
                                path="/survey/:id/files-and-images"
                                element={<SurveyFilesAndImages />}
                            />
                        </Route>
                    </Route>
                    <Route path="/login" element={<Login />} />
                </Routes>
            </Hocs>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
