import React from "react";
import classNames from "classnames";

import styles from "@components/Card.module.scss";

export interface CardProps {
    title: string;
    tags?: string[];
    published?: string;
    updated?: string;
    selected?: boolean;
}

const Card: React.FC<CardProps> = ({ title, tags, published, updated, selected = false }) => {
    const className = classNames(styles.card, {
        [styles.selected]: selected
    });

    return (
        <div className={className}>
            <div className={styles.title}>{title}</div>

            <div className={styles.footer}>
                {tags &&
                    tags.map((tag) => (
                        <div key={tag} className={styles.tag}>
                            {tag}
                        </div>
                    ))}
            </div>
            <div className={styles.times}>
                {updated && <div className={styles.date}>updated: {updated}</div>}
                {published && <div className={styles.date}>published: {published}</div>}
            </div>
        </div>
    );
};

export default Card;
