import React from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button, PageHeader } from "antd";
import MultilingualForm from "@components/forms/multilingual";
import { useAddSurveyMutation, useGetTimezonesQuery } from "@store/api/surveys";
import inputs from "./form/new-survey-inputs";
import { timeZoneToUtc } from "@utils/timezones";
import { SurveyFormItems } from "./types";

import styles from "./NewSurvey.module.scss";
import ErrorMessage from "@components/ErrorMessage";

const NewSurvey = () => {
    const dateFormat = "YYYY-MM-DD HH:mm";
    const navigate = useNavigate();

    const [addSurvey, { data, isLoading, isSuccess, error: errorAddSurvey, reset }] =
        useAddSurveyMutation();
    const {
        data: timezones,
        isFetching: isFetchingTimezones,
        error: errorTimezones
    } = useGetTimezonesQuery("");

    const handleBack = () => navigate(`/`);
    const handleOnSubmit = async (values: SurveyFormItems) => {
        const openAtDate = values.open_at
            ? timeZoneToUtc({ time: values.open_at, zone: values.timezone, format: dateFormat })
            : undefined;
        const closeAtDate = values.close_at
            ? timeZoneToUtc({ time: values.close_at, zone: values.timezone, format: dateFormat })
            : undefined;

        await addSurvey({
            ...values,
            open_at: openAtDate,
            close_at: closeAtDate
        });
    };

    const handleResetSuccess = () => reset();

    const error = errorAddSurvey || errorTimezones;

    return (
        <div>
            <PageHeader onBack={handleBack} title="New survey" />
            <div className={styles.page}>
                {!isSuccess && !error && !isFetchingTimezones && (
                    <MultilingualForm
                        inputs={inputs({ timezones: timezones })}
                        onSubmit={handleOnSubmit}
                        actions={[
                            <Button
                                key="submit"
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                            >
                                Create new
                            </Button>
                        ]}
                    />
                )}
                {isSuccess && !error && (
                    <div className={styles.success}>
                        <div className={styles.title}>Successfully created the survey</div>
                        <div className={styles.actions}>
                            <Button type="primary" onClick={handleResetSuccess}>
                                Create new
                            </Button>
                            <Link to={`/survey/${data.id}`}>
                                <Button>Go to created survey</Button>
                            </Link>
                        </div>
                    </div>
                )}
                {error && <ErrorMessage message="There was an error, please try again later" />}
            </div>
        </div>
    );
};

export default NewSurvey;
